<template>
  <base-section id="valueProposition">
    <div style="padding-bottom: 16px; max-width: 840px; text-align: center; margin: auto; font-weight: 600; font-size: x-large">
      Tentifly empowers your business with seamless automation of your order management process, enabling you to focus on what really matters - growing your business. With no commission fees and a suite of tools to help you manage your operations, Tentifly makes it easy to streamline your business and achieve your goals.
    </div>
  </base-section>
</template>

<script>
export default {
  name: "SectionValueProposition",
  data: () => ({}),
};
</script>
